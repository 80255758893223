import IWarehouseCertificates from 'domain/IWarehouseCertificates'
import IV2ContentService from './IV2ContentService'
import {IPaginatedExtendedResult} from 'domain/IPaginateResult'
import ISellExcessRequest, {IRecommendedExcessPrice} from 'domain/ISellExcessRequest'
import ICertificateOffer from 'domain/ICertificateOffer'
import {ICreateOnbBuyerSiteReq, ICreateOnbSellerSiteReq} from 'domain/ICreateOnbSiteReq'
import IMatchingScore from 'domain/IMatchingScore'
import ITimeseriesItem, {SellExcessPayload} from 'domain/INewTimeseriesItem'
import {INewOffer, INewSellerOfferPayload} from 'domain/IOffer'
import {ISellerOnboardState} from 'domain/ISellerOnboardState'
import IProduct, {IBasicProduct, IUpdateProduct} from 'domain/IProduct'
import IProposalDetails, {IProposalDetailsBody} from 'domain/IProposalDetails'
import ISite, {
  IBasicSite,
  ICreateBuyerSite,
  ICreateSellerSite,
  ISiteOnbSummary,
  IUpdateBuyerSiteGeneral,
  IUpdateSellerSiteGeneral,
  IUploadBuyerConsumption,
  IUploadSellerProduction,
  SiteProductionTechnology,
} from 'domain/ISite'
import ISitesDashboard from 'domain/ISitesDashboard'
import {CreateOnboardProductReq} from 'domain/IUploadProductRequest'
import {IContractedVolume, ICoverageOverview, IFinancialPerformance, ISiteTimeseriesResult} from 'domain/Portfolio'
import {Interval} from './IContentService'
import ITenderRequest from 'domain/ITenderRequest'
import IContract, {IBuyerContract} from 'domain/IContract'
import {IBuyerOnboardState} from 'domain/IBuyerOnboardState'
import IExternalContract, {IExternalContractPayload, IExternalContractUploadPayload} from 'domain/IExternalContract'
import {
  IGenerateTestCertsPayload,
  IMarketListing,
  IMarketListingReport,
  IMarketListingUpdatePayload,
  INewMarketListing,
  CertSitePayload,
} from 'domain/IMarketListing'
import IAttachment from 'domain/IAttachment'
import {IDetailedProfile} from 'domain/IDetailedProfile'
import {CertificateTech, ITestCertificates} from 'domain/IRegoCertificate'
import {
  adminSellRequests,
  adminWarehouse,
  buyerMarketplaceProducts,
  certificateOffer,
  consumption,
  contractedVolumes,
  detailedProfile,
  externalContracts,
  financialPerformance,
  greenCertificates,
  marketListingCertReport,
  marketListings,
  offers,
  portfolioSites,
  portfolioSitesDashboard,
  proposalDetails,
  tenderResponses,
  buyerContracts,
  mockSubscriptions,
  buyerCompanyInformation,
  rawSpotPrices,
  aggrSpotPrices,
  managedPartyMembers,
} from './seed/mocksv2'
import LocalStorageDataService from './LocalStorageDataService'
import LocalStorageService from './LocalStorageService'
import IParty, {OnboardingStatus, ISubscriptionPlan, PartyRole} from 'domain/IParty'
import {CurrencyCode, IUnit, PriceUnit} from 'domain/IPrice'
import ILocation, {Country} from 'domain/ILocation'
import {locations, users, parties} from './seed/mocks'
import IKPI, {IKPITargets} from 'domain/IKPI'
import {ITenderRespond, ITenderRespondCreatePayload} from 'domain/ITenderRespond'
import {IPaddle} from 'domain/IPaddle'
import ISubscription from 'domain/ISubscription'
import {IRegisterCreatePartyPayload, IRegisterPayload, IUserProfile} from 'domain/IUserProfile'
import {ICompanyInformation} from 'domain/ICompanyInformation'
import {ResourceId} from 'types'
import {IAggrGreenTarif, IAggrSpotPrice, IRawGreenTarif, IRawSpotPrice} from 'domain/IMarketIntelligence'
import IUserInvite from 'domain/IUserInvite'
import {ICreateSellerCustomer} from 'domain/ISellerCustomer'
import {IPasswordFromInvite} from 'domain/IPasswordFromInvite'
import IPartyMember from 'domain/IPartyMember'
import {IDetailedPartyMemberWithUsers} from 'domain/IDetailedPartyMemberWithUsers'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {IPartyMemberCreateRequest} from 'domain/IPartyMemberCreateRequest'

export default class MockV2ContentService implements IV2ContentService {
  private readonly detailedProfile: LocalStorageService<IDetailedProfile> = new LocalStorageService<IDetailedProfile>(
    'detailedProfile',
  )
  private readonly portfolioSites: LocalStorageDataService<ISite> = new LocalStorageDataService<ISite>('portfolioSites')
  private readonly buyerMarketplaceProducts: LocalStorageDataService<IProduct> = new LocalStorageDataService<IProduct>(
    'buyerMarketplaceProducts',
  )
  private readonly sellerOnboardingState: LocalStorageService<ISellerOnboardState> =
    new LocalStorageService<ISellerOnboardState>('sellerOnboardingState')

  private readonly buyerOnboardingState: LocalStorageService<IBuyerOnboardState> =
    new LocalStorageService<IBuyerOnboardState>('buyerOnboardingState')

  private readonly externalContracts: LocalStorageDataService<IExternalContract> =
    new LocalStorageDataService<IExternalContract>('externalContracts')
  private readonly marketListings: LocalStorageDataService<IMarketListing> =
    new LocalStorageDataService<IMarketListing>('marketListings')

  private readonly tenderResponses: LocalStorageDataService<ITenderRespond> =
    new LocalStorageDataService<ITenderRespond>('tenderResponses')

  private readonly offers: LocalStorageDataService<INewOffer> = new LocalStorageDataService<INewOffer>('newOffers')
  private readonly onboardingState: LocalStorageService<OnboardingStatus> = new LocalStorageService<OnboardingStatus>(
    'onboardingState',
  )

  private readonly noPartyRole: LocalStorageService<boolean> = new LocalStorageService<boolean>('noPartyRole')
  private readonly userActivated: LocalStorageService<boolean> = new LocalStorageService<boolean>('userActivated')

  private readonly partySubscription: LocalStorageService<ISubscriptionPlan> =
    new LocalStorageService<ISubscriptionPlan>('partySubscription')

  private readonly buyerGuideCompletedOn: LocalStorageService<string> = new LocalStorageService<string>(
    'buyerGuideCompletedOn',
  )

  private readonly users = new LocalStorageDataService<IUserProfile>('users')

  public constructor() {
    this.detailedProfile.setItem(detailedProfile)
    this.portfolioSites.setItems(portfolioSites.data)
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.NOT_STARTED})
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.NOT_STARTED})
    this.buyerMarketplaceProducts.setItems(buyerMarketplaceProducts.data)
    this.externalContracts.setItems(externalContracts.data)
    this.marketListings.setItems(marketListings.data)
    this.tenderResponses.setItems(tenderResponses.data)
    this.offers.setItems(offers.data)
    this.onboardingState.setItem(OnboardingStatus.COMPLETED)
    this.noPartyRole.setItem(false)
    this.userActivated.setItem(true)
    this.partySubscription.setItem(ISubscriptionPlan.ENTERPRISE)
    this.buyerGuideCompletedOn.setItem(detailedProfile.profile.buyerGuideCompletedOn)
  }

  public async getProductLocation(partyId: any, productId: string): Promise<ILocation> {
    return {
      addressCountry: Country.GBR,
      addressCity: 'London',
      addressPostCode: 'EC1A 1BB',
      id: 1,
      addressStreet: '123 Main St',
      coordinatesLat: 51.5074,
      coordinatesLon: -0.0796,
      placeId: '1234567890',
    }
  }

  public async getRawGreenTarif(
    partyId: any,
    countries: Country[],
    start: string,
    end: string,
  ): Promise<IRawGreenTarif> {
    return null
  }

  public async getAggrGreenTarif(
    partyId: any,
    countries: Country[],
    start: string,
    end: string,
    interval: Interval,
  ): Promise<IAggrGreenTarif> {
    return null
  }

  public async getSiteTimeseries(
    partyId: any,
    siteId: number,
    start: string,
    end: string,
    interval: Interval,
  ): Promise<ISiteTimeseriesResult> {
    return null
  }

  public async resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void> {
    return
  }

  public async createPartyMember(partyId: number, payload: IPartyMemberCreateRequest): Promise<IPartyMember> {
    return null
  }
  public async updatePartyMember(
    partyId: number,
    partyMemberId: number,
    payload: IPartyMemberUpdateRequest,
  ): Promise<IDetailedPartyMemberWithUsers> {
    const member = managedPartyMembers.find(m => m.detMember.member.id === partyMemberId)
    member.user.firstName = payload.firstName
    member.user.lastName = payload.lastName

    return member
  }

  public async disablePartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    const member = managedPartyMembers.find(m => m.detMember.member.id === partyMemberId)
    member.user.enabled = false

    return null
  }

  public async enablePartyMember(partyId: number, partyMemberId: number): Promise<IPartyMember> {
    const member = managedPartyMembers.find(m => m.detMember.member.id === partyMemberId)
    member.user.enabled = true

    return null
  }

  public async getPartyMembers(
    asPartyId: number,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IDetailedPartyMemberWithUsers>> {
    return {
      data: managedPartyMembers,
      page,
      pageSize: size,
      totalElements: managedPartyMembers.length,
      totalPages: 1,
    }
  }

  public async getPartyMember(partyId: number, partyMemberId: number): Promise<IDetailedPartyMemberWithUsers> {
    return managedPartyMembers.find(m => m.detMember.member.id === partyMemberId)
  }

  public async getUserFromInvite(accessCode: string): Promise<IUserInvite> {
    return {
      email: 'test@test.com',
      firstName: 'Test',
      lastName: 'Test',
    }
  }

  public async getSellerSite(partyId: any, siteId: number): Promise<ISite> {
    return portfolioSites.data.find(s => s.id === siteId)
  }
  public async createSellerSite(partyId: any, payload: ICreateSellerSite): Promise<ISite> {
    return {...payload, id: portfolioSites.data.length + 1}
  }
  public async updateSellerSiteGeneral(
    partyId: any,
    siteId: number,
    payload: IUpdateSellerSiteGeneral,
  ): Promise<ISite> {
    return {...payload, id: siteId}
  }
  public async uploadSellerSiteProduction(
    partyId: any,
    siteId: number,
    payload: IUploadSellerProduction,
  ): Promise<ISite> {
    return this.portfolioSites.getItem(siteId)
  }
  public async sellCertExcessRequest(partyId: ResourceId, payload: SellExcessPayload): Promise<ISellExcessRequest> {
    throw new Error('Method not implemented.')
  }
  public async getPriceRecommendation(
    partyId: any,
    technologyKind: SiteProductionTechnology,
  ): Promise<IRecommendedExcessPrice> {
    return {
      price: 5.0,
      currency: CurrencyCode.GBP,
    }
  }
  public async acceptExcessSellRequest(sellRequestId: any): Promise<ISellExcessRequest> {
    return adminSellRequests.data.find(r => r.id === +sellRequestId)
  }
  public async declineExcexsSellRequest(sellRequestId: any): Promise<ISellExcessRequest> {
    return adminSellRequests.data.find(r => r.id === +sellRequestId)
  }
  public async getPublicExcessSellRequests(partyId: any): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    return adminSellRequests
  }
  public async getCompanyInformation(partyId: any): Promise<ICompanyInformation> {
    return buyerCompanyInformation
  }
  public async saveCompanyInformation(partyUpdate: ICompanyInformation): Promise<ICompanyInformation> {
    return {
      ...buyerCompanyInformation,
      ...partyUpdate,
    }
  }
  public async sendToSellerReview(partyId: any, offerId: any): Promise<void> {
    return
  }
  public async approveOffer(partyId: any, offerId: any): Promise<void> {
    return
  }
  public async declineOffer(partyId: any, offerId: any): Promise<void> {
    return
  }
  public async updateUserProfile(payload: IUserProfile): Promise<IUserProfile> {
    const {profile, memberships} = this.detailedProfile.getItem()
    this.detailedProfile.setItem({profile: {...profile, ...payload}, memberships})
    return null
  }
  public async cancelSubscription(partyId: any, subscriptionId: number): Promise<ISubscription> {
    this.partySubscription.setItem(ISubscriptionPlan.BASIC)
    return mockSubscriptions.find(s => s.id === subscriptionId)
  }
  public async getSubscription(partyId: any, subscriptionId: number): Promise<ISubscription> {
    return mockSubscriptions.find(s => s.id === subscriptionId)
  }
  public async getSubscriptions(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<ISubscription>> {
    return {
      data: mockSubscriptions,
      page,
      pageSize: size,
      totalElements: 0,
      totalPages: 0,
    }
  }
  public async getBuyerContracts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IBuyerContract>> {
    return buyerContracts
  }
  public async getPaddlePricing(partyId: number): Promise<IPaddle> {
    return {
      paddleClientSideToken: 'test_20f33eb54b85c26bfad0e9f8466',
      proPlanMonthlyPriceId: 'pri_01j7jk8nvy6kmxrvben2art1gy',
      proPlanAnnualPriceId: 'pri_01j7jkf75ejvmsb78v3a5qavhn',
      enterprisePlanMonthlyPriceId: 'pri_01j7jkzcea7q6j8whj0djhg9hw',
      enterprisePlanAnnualPriceId: 'pri_01j7jkxk1vbpazrj25njvmqbt8',
    }
  }

  public async completeBuyerGuide(userId: any): Promise<void> {
    this.buyerGuideCompletedOn.setItem('2024-01-01')
  }

  public async getCertificateProposalDetails(
    partyId: any,
    payload: IProposalDetailsBody,
    interval?: Interval,
  ): Promise<IProposalDetails> {
    return proposalDetails
  }

  public async getPorfolioContractedVolumes(partyId: any, interval: Interval): Promise<IContractedVolume> {
    throw contractedVolumes
  }

  public async getCoverageOverview(partyId: any, interval: Interval): Promise<ICoverageOverview> {
    throw contractedVolumes
  }

  public async getPortfolioSites(
    partyId: number,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<ISite>> {
    const sites = this.portfolioSites.getItems()
    return {...portfolioSites, data: sites}
  }

  public async getPortfolioSitesDashboard(
    partyId: number,
    interval: Interval,
    startDate: string,
    endDate: string,
  ): Promise<ISitesDashboard> {
    return portfolioSitesDashboard
  }

  public async getSellerOnboardingState(partyId: number): Promise<ISellerOnboardState> {
    return this.sellerOnboardingState.getItem()
  }

  public async getBuyerOnboardingState(partyId: number): Promise<IBuyerOnboardState> {
    return this.buyerOnboardingState.getItem()
  }

  public async startSellerOnboarding(partyId: number): Promise<ISellerOnboardState> {
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.SELLER_STARTED})
    return this.sellerOnboardingState.getItem()
  }

  public async startBuyerOnboarding(partyId: number): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.BUYER_STARTED})
    return this.buyerOnboardingState.getItem()
  }

  public async createSellerOnbSite(partyId: any, request: ICreateOnbSellerSiteReq): Promise<ISellerOnboardState> {
    const {meterReference} = request

    this.sellerOnboardingState.setItem({
      onboardingStatus: OnboardingStatus.SELLER_SITE_CREATED,
      site: {
        technology: CertificateTech.SOLAR,
        generatedCertificates: 200,
        location: {addressCountry: Country.GBR},
        meterReference,
      },
    })
    return this.sellerOnboardingState.getItem()
  }

  public async createBuyerOnbSite(partyId: any, request: ICreateOnbBuyerSiteReq): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.BUYER_SITE_CREATED, site: {...request}})
    return this.buyerOnboardingState.getItem()
  }

  public async createSellerOnbProduct(id: number, req: CreateOnboardProductReq): Promise<ISellerOnboardState> {
    this.sellerOnboardingState.setItem({
      ...this.sellerOnboardingState.getItem(),
      onboardingStatus: OnboardingStatus.SELLER_PRODUCT_CREATED,
      product: {
        ...req,
        currency: CurrencyCode.GBP,
      },
    })
    return this.sellerOnboardingState.getItem()
  }

  public async publishOnboardingProduct(id: number): Promise<ISellerOnboardState> {
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.COMPLETED})
    return this.sellerOnboardingState.getItem()
  }

  public async createBuyerOnbConsumption(partyId: any, attachmentId: number): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({
      ...this.buyerOnboardingState.getItem(),
      onboardingStatus: OnboardingStatus.BUYER_PROFILE_UPLOADED,
      uploadedProfile: {volumeMwh: 99.999},
    })
    return this.buyerOnboardingState.getItem()
  }

  public async completeBuyerOnboarding(partyId: any): Promise<IBuyerOnboardState> {
    this.buyerOnboardingState.setItem({onboardingStatus: OnboardingStatus.COMPLETED})
    return this.buyerOnboardingState.getItem()
  }

  public async getPortfolioMatchingScores(partyId: any): Promise<IMatchingScore> {
    return {hourlyPercent: 80, monthlyPercent: 85, yearlyPercent: 90.5}
  }

  public async getGreenCertificatesData(
    partyId: any,
    year: number,
    month: string,
    interval: Interval,
  ): Promise<ITimeseriesItem> {
    return greenCertificates
  }

  public async getCertificateProducts(
    partyId: any,
    month: string,
    year: number,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IProduct>> {
    const products = this.buyerMarketplaceProducts.getItems()
    return {...buyerMarketplaceProducts, data: products}
  }

  public async getCertificateProduct(partyId: any, productId: any): Promise<IProduct> {
    const products = this.buyerMarketplaceProducts.getItems()

    return products.find(p => p.id === +productId)
  }

  public async getCertificatePurchaseOffer(partyId: any, offerId: any): Promise<ICertificateOffer> {
    return certificateOffer
  }

  public async getSnapshottedCertificatePurchaseOffer(partyId: any, offerId: any): Promise<ICertificateOffer> {
    return certificateOffer
  }

  public async saveCertificateOffer(partyId: any, payload: IProposalDetailsBody): Promise<ICertificateOffer> {
    return certificateOffer
  }

  public async getAdminExcessSellRequests(): Promise<IPaginatedExtendedResult<ISellExcessRequest>> {
    return adminSellRequests
  }

  public async getWarehouseCertificates(): Promise<IWarehouseCertificates> {
    throw adminWarehouse
  }

  public async sendTenderRequest(partyId: any, body: ITenderRequest): Promise<ITenderRequest> {
    return null
  }

  public async getPortfolioProducts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IProduct>> {
    const products = this.buyerMarketplaceProducts.getItems()

    return {...buyerMarketplaceProducts, data: products}
  }

  public async signContract(partyId: any, contractId: any): Promise<IContract> {
    return null
  }

  public async getExternalContracts(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IExternalContract>> {
    const contracts = this.externalContracts.getItems()

    return {...externalContracts, data: contracts}
  }
  public async getExternalContract(partyId: any, contractId: string): Promise<IExternalContract> {
    return externalContracts.data.find(c => c.id === +contractId)
  }

  public async uploadAttachment(partyId: any, file: File): Promise<IAttachment> {
    return {id: 1, fileName: 'testFile', size: 400, mimeType: 'application/pdf'}
  }

  public async saveExternalContract(partyId: any, contract: IExternalContractPayload): Promise<IExternalContract> {
    this.externalContracts.addItem(contract)

    return contract
  }

  public async getSellerOnbSiteSummary(partyId: any, interval: Interval): Promise<ISiteOnbSummary> {
    return {siteId: '1', siteName: 'test', timeseries: consumption}
  }
  public async getBuyerOnbSiteSummary(partyId: any, interval: Interval): Promise<ISiteOnbSummary> {
    return {siteId: '1', siteName: 'test', timeseries: consumption}
  }

  public async getFinancialPerformance(
    partyId: any,
    startDate: string,
    endDate: string,
    interval: Interval,
  ): Promise<IFinancialPerformance> {
    return financialPerformance
  }

  public async getMarketListings(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IMarketListing>> {
    const listings = this.marketListings.getItems()

    return {...marketListings, data: listings}
  }

  public async getBasicProducts(partyId: any): Promise<IBasicProduct[]> {
    return [
      {
        id: 10004,
        name: "Exeter Solar REGO's",
        description: "Exeter Solar generation following REGO's",
      },
      {
        id: 10002,
        name: "Dinorwig Hydro REGO's",
        description: "Dinorwig Hydro Generation following REGO's",
      },
      {
        id: 10001,
        name: "MAN Solar REGO's",
        description: "Generation Following REGO's Manchester Solar Airport  ",
      },
    ]
  }

  public async getBasicSites(partyId: any): Promise<IBasicSite[]> {
    return [
      {
        id: 100,
        name: 'Site1',
        location: locations[1],
      },
      {
        id: 101,
        name: 'Site2',
        location: locations[2],
      },
    ]
  }

  public async createMarketListing(partyId: any, payload: INewMarketListing): Promise<boolean> {
    this.marketListings.addItem({...this.marketListings.getItems()[0], certPrice: payload.listingDetails.price})

    return true
  }

  public async updateProduct(partyId: any, productId: string, payload: IUpdateProduct): Promise<IProduct> {
    this.buyerMarketplaceProducts.updateItem({id: +productId, ...payload} as unknown as IProduct)

    return this.buyerMarketplaceProducts.getItem(+productId)
  }

  public async getMarketListingsByProduct(
    partyId: any,
    productId: string,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<IMarketListing>> {
    return {
      ...marketListings,
      data: this.marketListings.getItems().filter(l => l.productId === +productId),
    }
  }

  public async getBuyerProposals(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<INewOffer>> {
    return {...offers, data: this.offers.getItems()}
  }

  public async getSellerProposals(partyId: any, page: number, size: number): Promise<INewSellerOfferPayload> {
    return {
      ...offers,
      data: this.offers.getItems(),
      summary: {
        totalVolumeMwh: 4073.0,
        avgVolumeMwh: 162.92,
        pricePerMwh: 8.82,
        priceUnit: PriceUnit.GBP_MWH,
      },
    }
  }

  public async getMarketListingById(partyId: any, listingId: number): Promise<IMarketListing> {
    return this.marketListings.getItem(listingId)
  }

  public async getMarketListingCertReport(partyId: any, listingId: number): Promise<IMarketListingReport> {
    return marketListingCertReport
  }

  public async updateMarketListing(
    partyId: any,
    listingId: number,
    payload: IMarketListingUpdatePayload,
  ): Promise<IMarketListing> {
    this.marketListings.updateItem({...this.marketListings.getItem(listingId), ...payload})
    return this.marketListings.getItem(listingId)
  }

  public async createBuyerSite(partyId: any, payload: ICreateBuyerSite): Promise<ISite> {
    const newSite = this.portfolioSites.addItem(payload)

    return newSite
  }

  public async getBuyerSite(partyId: any, siteId: number): Promise<ISite> {
    return this.portfolioSites.getItem(siteId)
  }

  public async updateBuyerSiteGeneral(partyId: any, siteId: number, payload: IUpdateBuyerSiteGeneral): Promise<ISite> {
    const updatedSite = this.portfolioSites.updateItem({...this.portfolioSites.getItem(siteId), ...payload})

    return updatedSite
  }

  public async uploadBuyerSiteConsumption(
    partyId: any,
    siteId: number,
    payload: IUploadBuyerConsumption,
  ): Promise<ISite> {
    return this.portfolioSites.getItem(siteId)
  }

  public async generateOnboardingTestCertificates(partyId: any): Promise<ITestCertificates> {
    return {start: 'G00001PVEN0000009626010624300624TST', end: 'G00001PVEN0000009825010624300624TST'}
  }

  public async getDetailedProfile(): Promise<IDetailedProfile> {
    const {profile, memberships} = this.detailedProfile.getItem()

    if (this.buyerGuideCompletedOn) {
      profile.buyerGuideCompletedOn = this.buyerGuideCompletedOn.getItem()
    }

    if (!this.userActivated.getItem()) {
      profile.activated = false
    }

    const parsedMemberships = memberships.map(m => ({
      ...m,
      party: {
        ...m.party,
        onboardingStatus: this.onboardingState.getItem(),
        partyRole: this.noPartyRole.getItem() ? null : m.party.partyRole,
        subscriptionPlan: this.partySubscription.getItem(),
      },
    }))

    return {profile, memberships: parsedMemberships, themes: []}
  }

  public async generateMarketListingTestCertificates(
    partyId: any,
    payload: IGenerateTestCertsPayload,
  ): Promise<ITestCertificates> {
    return {start: 'G00001PVEN0000009626010624300624TST', end: 'G00001PVEN0000009825010624300624TST'}
  }

  public async uploadExternalContractProduction(
    partyId: any,
    contractId: number,
    payload: IExternalContractUploadPayload,
  ): Promise<boolean> {
    return true
  }

  public async skipSellerOnboarding(partyId: any): Promise<boolean> {
    this.sellerOnboardingState.setItem({onboardingStatus: OnboardingStatus.SELLER_SKIPPED})

    return true
  }

  public async getBuyerKPIs(partyId: any, month: string, year: number): Promise<IKPI> {
    return {
      coverage: {
        hourlyPercent: 51.9,
        monthlyPercent: null,
        yearlyPercent: 14.42,
      },
      carbon: {
        unit: 'TON',
        yearEmissions: 36.26,
        yearSavedEmissions: 15.0,
        monthEmissions: 36.26,
        monthSavedEmissions: 12.0,
      },
      targetAchievingRates: {
        hourly: 100,
        monthly: 60,
        yearly: 0,
      },
      annualConsumptionMwh: 5500,
    }
  }

  public async getBuyerPartialKPIs(partyId: any, month: string, year: number): Promise<IKPI> {
    return {
      coverage: {
        hourlyPercent: 51.9,
        monthlyPercent: null,
        yearlyPercent: 14.42,
      },
      annualConsumptionMwh: 5500,
    }
  }

  public async getKPITargets(partyId: any): Promise<{targets: IKPITargets}> {
    return {
      targets: {
        hourlyPercent: 50,
        monthlyPercent: 50,
        yearlyPercent: 50,
      },
    }
  }

  public async createKPITargets(partyId: any, payload: IKPITargets): Promise<boolean> {
    return true
  }

  public async updateKPITargets(partyId: any, payload: IKPITargets): Promise<IKPITargets> {
    return {
      hourlyPercent: 50,
      monthlyPercent: 50,
      yearlyPercent: 50,
    }
  }

  public async getTenderResponds(
    partyId: any,
    page: number,
    size: number,
  ): Promise<IPaginatedExtendedResult<ITenderRespond>> {
    const items = this.tenderResponses.getItems()

    return {...tenderResponses, data: items}
  }
  public async getTenderRespond(partyId: any, id: number): Promise<ITenderRespond> {
    return this.tenderResponses.getItem(id)
  }
  public async createTenderRespond(partyId: any, payload: ITenderRespondCreatePayload): Promise<ITenderRespond> {
    return this.tenderResponses.addItem(payload)
  }
  public async updateTenderRespond(partyId: any, id: number, payload: ITenderRespond): Promise<ITenderRespond> {
    return this.tenderResponses.updateItem({...payload})
  }
  public async downloadAttachment(partyId: any, attachmentId: string): Promise<string> {
    return Promise.resolve(attachmentId)
  }

  public async getRawSpotPrices(
    partyId: any,
    countries: Country[],
    start: string,
    end: string,
  ): Promise<IRawSpotPrice> {
    return rawSpotPrices as unknown as IRawSpotPrice
  }

  public async getAggrSpotPrices(
    partyId: any,
    countries: Country[],
    start: string,
    end: string,
    interval: Interval,
  ): Promise<IAggrSpotPrice> {
    return aggrSpotPrices as unknown as IAggrSpotPrice
  }

  public async addSellerCustomer(partyId: any, payload: ICreateSellerCustomer): Promise<ICompanyInformation> {
    return {id: 3, ...payload} as ICompanyInformation
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    return Promise.resolve()
  }

  public async register(payload: IRegisterPayload): Promise<IUserProfile> {
    const {email, firstName, lastName, password} = payload

    const newUser = this.users.addItem({
      email,
      phoneNumber: '+12345678',
      firstName,
      lastName,
    } as unknown as IUserProfile)

    return this.users.getItem(newUser.id)
  }
  public async confirmEmail(code: string): Promise<void> {
    const {profile, memberships} = this.detailedProfile.getItem()
    this.userActivated.setItem(true)
    this.detailedProfile.setItem({profile: {...profile, activated: true}, memberships: []})
  }
  public async resendConfirmationEmail(): Promise<void> {
    return
  }
  public async registerCreateParty(payload: IRegisterCreatePartyPayload): Promise<IParty> {
    const profile = this.detailedProfile.getItem().profile
    const memberships = detailedProfile.memberships

    const parsedMemberships = memberships.map(m => ({
      ...m,
      party: {
        ...m.party,
        onboardingStatus: this.onboardingState.getItem(),
        partyRole: this.noPartyRole.getItem() ? null : m.party.partyRole,
        subscriptionPlan: this.partySubscription.getItem(),
      },
    }))

    this.detailedProfile.setItem({profile, memberships: parsedMemberships})

    return parties[0]
  }
}
