import React from 'react'
import {dateFilterAtom} from 'atoms/portfolioSites'
import Box from 'components/Box'
import InnerScroll from 'components/InnerScroll'
import PeriodSelect from 'components/PeriodSelect'
import ISite, {SiteType} from 'domain/ISite'
import {useSetAtom} from 'jotai'
import SiteDashboardChart from '../../SiteDashboard'
import {generateYears, getCurrentYear} from 'helpers/date'

interface IProps extends React.PropsWithChildren {
  site: ISite
}

const SellerProfile: React.FC<IProps> = ({site}) => {
  const setDateFilter = useSetAtom(dateFilterAtom)
  return (
    <Box>
      <InnerScroll noRightPad>
        <PeriodSelect
          withQuickLinks
          withoutYesterday
          setValue={setDateFilter}
          setQuickLinkValue={setDateFilter}
          customYears={generateYears(getCurrentYear() + 2, 6)}
        />
        <Box pad={{top: 2}}>
          <SiteDashboardChart siteName={site.name} siteType={SiteType.PRODUCTION} />
        </Box>
      </InnerScroll>
    </Box>
  )
}

export default SellerProfile
