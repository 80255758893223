import {dateFilterAtom} from 'atoms/portfolioSites'
import Box from 'components/Box'
import InnerScroll from 'components/InnerScroll'
import PeriodSelect from 'components/PeriodSelect'
import ISite, {SiteType} from 'domain/ISite'
import {useSetAtom} from 'jotai'
import React from 'react'
import SiteDashboardChart from '../SiteDashboard'

interface IProps extends React.PropsWithChildren {
  site: ISite
}

const BuyerProfile: React.FC<IProps> = ({site}) => {
  const setDateFilter = useSetAtom(dateFilterAtom)
  return (
    <Box>
      <InnerScroll noRightPad>
        <PeriodSelect withQuickLinks withoutYesterday setValue={setDateFilter} setQuickLinkValue={setDateFilter} />
        <Box pad={{top: 2}}>
          <SiteDashboardChart siteName={site.name} siteType={SiteType.CONSUMPTION} />
        </Box>
      </InnerScroll>
    </Box>
  )
}

export default BuyerProfile
