import {atom, Atom, Getter, PrimitiveAtom, WritableAtom} from 'jotai'
import {loadable as jotaiLoadable, unwrap} from 'jotai/utils'
import {atomWithReducer} from 'jotai/utils'
import ApiError from 'exceptions/ApiError'
import {partyAtom} from './party'
import {isEqual} from 'lodash-es'

function isWritableAtom(object: any): object is WritableAtom<any, [], null> {
  return 'write' in object
}

enum AccessLevel {
  PRO,
  ENTERPRISE,
}

interface IOptions {
  bypassParty?: boolean
  nullIsLoading?: boolean
  acessLevel?: AccessLevel
}

export const loadable = <Value>(anAtom: Atom<Value> | WritableAtom<Value, [], null>, options: IOptions = {}) => {
  return atom(
    get => {
      const party = get(partyAtom)

      if (!party && !options.bypassParty) {
        return {loading: true, data: null}
      }

      const result = get(jotaiLoadable(anAtom))

      if (options.nullIsLoading && result.state === 'hasData' && result.data === null) {
        return {loading: true, data: null}
      }

      if (result.state === 'hasData') {
        return {loading: false, data: result.data}
      }

      if (result.state === 'hasError') {
        return {loading: false, error: result.error as ApiError}
      }

      return {loading: true, data: null}
    },
    (_get, set) => {
      if (isWritableAtom(anAtom)) {
        set(anAtom)
      }
    },
  )
}

export const atomWithCompare = <Value>(initialValue: Value) => {
  return atomWithReducer(initialValue, (prev: Value, next: Value) => {
    if (isEqual(prev, next)) {
      return prev
    }

    return next
  })
}

type Selector<T> = (get: Getter) => T | Promise<T>

export const selectAtomWithSwr = <T>(selector: Selector<T>) => {
  const baseAtom = atom(get => selector(get))
  const unwrappedAtom = unwrap(baseAtom, prev => prev)

  return atom(get => get(unwrappedAtom) ?? {loading: true, data: null})
}

export const atomWithCleanup = <Value>(value: Value) => {
  const defaultAtom = atom(value) as PrimitiveAtom<Value>

  defaultAtom.onMount = setAtom => {
    return () => {
      setAtom(null)
    }
  }

  return defaultAtom
}
