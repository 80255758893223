import styled from 'styled-components'
import React, {lazy, Suspense, useEffect, useState} from 'react'
import useTheme from '../../hooks/useTheme'
import Box from '../Box'
import Text from '../Text'
import {formatNumber} from '../../helpers/format'
import useLocalization from 'hooks/useLocalization'
import ITheme from 'theme/ITheme'
import {Skeleton} from '@mui/material'

import Toolbar, {IProps as IToolbarProps} from './Toolbar'

const Line = lazy(() => import('react-chartjs-2').then(module => ({default: module.Bar})))

const minBarHeightPx = 8
const lowValuesStart = 0.01
const lowValuesEnd = 3

const showSmallValuesPlugin = {
  beforeRender: function (chartInstance) {
    const datasets = chartInstance.config.data.datasets

    for (let i = 0; i < datasets.length; i++) {
      const meta = datasets[i]._meta
      // It counts up every time you change something on the chart so
      // this is a way to get the info on whichever index it's at
      const metaData = meta[Object.keys(meta)[0]]
      const bars = metaData.data
      if (metaData.type !== 'bar') {
        continue
      }

      for (let j = 0; j < bars.length; j++) {
        const model = bars[j]._model
        const diff = model.base - model.y

        if (diff > lowValuesStart && diff < lowValuesEnd) {
          model.y = model.base - minBarHeightPx
        }
      }
    }
  },
}

const Container = styled.div`
  height: ${props => (props.theme as ITheme).charts.height}px;
  width: 100%;
  min-width: 0;

  canvas {
    width: 100% !important;
  }
`

export enum StackType {
  DEFAULT,
  STACKED,
  SECOND_STACK,
  SECONDARY,
  TERTIARY,
}

export enum ChartType {
  LINE = 'line',
  BAR = 'bar',
}

export interface ILine {
  key: string
  label: string
  color: string
  data: number[]
  order?: number
  showPoint?: boolean
  type: ChartType
  fill?: any
  hideTooltip?: boolean
  stepped?: boolean
  stackType?: StackType
  stack?: string
  dashed?: boolean
  hidden?: boolean
}

type ITypeLabels = {[P in StackType]?: string}

export interface IProps extends React.PropsWithChildren {
  lines: ILine[]
  labels: string[]
  dark?: boolean
  maxValue?: number
  minValue?: number
  typeLabels?: ITypeLabels
  errors?: string[]
  toolbar?: boolean | Omit<IToolbarProps, 'lines'>
  maxDecimals?: number
}

export const getMaxValue = (lines: ILine[]) => {
  const maxValues: number[] = lines.map(line => Math.max(...line.data))
  const stacks: number[][] = lines.filter(line => line.stackType === StackType.STACKED).map(line => line.data)
  const stackMaxValues: number[] = []

  for (const stack of stacks) {
    stack.forEach((value, index) => {
      stackMaxValues[index] = (stackMaxValues[index] || 0) + value
    })
  }

  return Math.max(...stackMaxValues, ...maxValues)
}

export const getMinValue = (lines: ILine[]) => {
  const minValues: number[] = lines.map(line => Math.min(...line.data))
  const stacks: number[][] = lines.filter(line => line.stackType === StackType.STACKED).map(line => line.data)
  const stackMaxValues: number[] = []

  for (const stack of stacks) {
    stack.forEach((value, index) => {
      stackMaxValues[index] = (stackMaxValues[index] || 0) + value
    })
  }

  return Math.min(...stackMaxValues, ...minValues)
}

const Chart: React.FC<IProps> = ({
  lines,
  labels,
  dark,
  maxValue,
  minValue,
  errors,
  toolbar,
  maxDecimals,
  typeLabels = {},
}) => {
  const theme = useTheme()
  const {translate} = useLocalization()
  const [linesLength, setLinesLength] = useState<number>(lines.length)
  const [showDots, setShowDots] = useState<boolean>(false)

  // This is necessary to capture the moment when line has been removed - removing a line causes chartjs to fail
  useEffect(() => {
    if (lines.length > linesLength) {
      setLinesLength(lines.length)
    }
  }, [lines.length, linesLength])

  useEffect(() => {
    const setPlugins = async () => {
      const {default: Chartjs} = await import('chart.js')
      Chartjs.pluginService.register(showSmallValuesPlugin)
    }
    setPlugins()
  }, [])

  const data = {
    labels: labels.map(label => translate(label)),
    datasets: lines.map(({key, label, color, data, type, order, showPoint, fill, stepped, stackType, dashed}) => ({
      label: label,
      fill: fill === undefined ? stackType === StackType.STACKED : fill,
      data: data.map(v => (Number.isNaN(v) ? 0 : formatNumber(v, maxDecimals))),
      backgroundColor: color,
      borderDash: dashed ? [5, 2] : null,
      borderColor: color,
      borderWidth: 3,
      lineTension: 0.2,
      steppedLine: stepped,
      yAxisID: stackType || StackType.DEFAULT,
      order,
      pointRadius: showPoint ? undefined : showDots ? undefined : 0,
      pointHitRadius: 5,
      type: type,
      stack: key === 'purchased' ? 'stack-1' : 'stack-2',
      barPercentage: 0.7,
    })),
  }

  // Replaces removed lines with dummies
  if (linesLength > data.datasets.length) {
    data.datasets = data.datasets.concat(
      Array(linesLength - data.datasets.length).fill({
        data: [],
        label: Math.random(),
        hidden: true,
        yAxisID: StackType.DEFAULT,
      }),
    )
  }

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },

    tooltips: {
      filter: tooltipItem => {
        return !lines[tooltipItem.datasetIndex]?.hideTooltip
      },
      mode: 'point',
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: dark ? theme.colors.light2 : theme.colors.light1,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          id: StackType.DEFAULT,
          position: 'left',
          stacked: false,
          offset: false,
          ticks: {
            min: minValue,
            max: maxValue,
            beginAtZero: true,
            fontColor: dark ? theme.colors.light2 : theme.colors.common.white,
          },
          gridLines: {
            zeroLineColor: dark ? theme.colors.light3 : theme.colors.light1,
            color: dark ? theme.colors.light3 : 'rgba(255, 255, 255, 0.5)',
          },
          scaleLabel: {
            display: true,
            labelString: typeLabels[StackType.DEFAULT] || 'MWh',
            fontColor: dark ? theme.colors.light2 : theme.colors.common.white,
          },
        },
        ...(lines.find(line => line.stackType === StackType.STACKED)
          ? [
              {
                id: StackType.STACKED,
                position: 'left',
                stacked: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  max: maxValue,
                  display: false,
                },
                scaleLabel: {
                  display: false,
                },
              },
            ]
          : []),
        ...(lines.find(line => line.stackType === StackType.SECOND_STACK)
          ? [
              {
                id: StackType.SECOND_STACK,
                position: 'left',
                stacked: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  max: maxValue,
                  display: false,
                },
                scaleLabel: {
                  display: false,
                },
              },
            ]
          : []),
        ...(lines.find(line => line.stackType === StackType.SECONDARY)
          ? [
              {
                id: StackType.SECONDARY,
                position: 'right',
                stacked: false,
                ticks: {
                  max: maxValue,
                  beginAtZero: true,
                  fontColor: dark ? theme.colors.light2 : theme.colors.common.white,
                },
                gridLines: {
                  zeroLineColor: dark ? theme.colors.light3 : theme.colors.light1,
                  color: dark ? theme.colors.light3 : 'rgba(255, 255, 255, 0.5)',
                },
                scaleLabel: {
                  display: true,
                  labelString: typeLabels[StackType.SECONDARY] || 'MWh',
                  fontColor: dark ? theme.colors.light2 : theme.colors.common.white,
                },
              },
            ]
          : []),
        ...(lines.find(line => line.stackType === StackType.TERTIARY)
          ? [
              {
                id: StackType.TERTIARY,
                position: 'left',
                stacked: true,
                gridLines: {
                  display: false,
                },
                ticks: {
                  max: maxValue,
                  display: false,
                },
                scaleLabel: {
                  display: false,
                },
              },
            ]
          : []),
      ],
    },
  }

  const chart = (
    <Box grow fillHeight onMouseEnter={() => setShowDots(true)} onMouseLeave={() => setShowDots(false)}>
      <Container>
        <Suspense
          fallback={
            <Skeleton
              animation="wave"
              sx={{bgcolor: theme.colors.outline}}
              variant="rectangular"
              height="100%"
              data-cy="chart-loader"
            />
          }
        >
          <Line data={data} options={options} />
        </Suspense>
      </Container>
      {errors?.map((message, index) => (
        <Text color={theme.colors.error} size="small" key={index}>
          {message}
        </Text>
      ))}
    </Box>
  )

  if (toolbar) {
    const toolbarProps: Omit<IToolbarProps, 'lines'> = typeof toolbar === 'object' ? toolbar : {}
    const filteredLines = lines.filter(line => !line.hidden)

    return (
      <Toolbar {...toolbarProps} lines={filteredLines}>
        {chart}
      </Toolbar>
    )
  }

  return chart
}

Chart.defaultProps = {
  maxDecimals: 3,
}

export default Chart
